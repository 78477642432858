import Head from 'next/head';

import { useAppContext } from './appContext';

interface Props {
  title: string;
}

export const PageTitle = ({ title }: Props) => {
  const { tenant } = useAppContext();

  return (
    <Head>
      <title>{`${tenant?.name} - ${title}`}</title>
    </Head>
  );
};
